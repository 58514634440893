@import "@fontsource/poppins/300.css";
@import "@fontsource/poppins/400.css";
@import "@fontsource/poppins/500.css";
@import "@fontsource/poppins/700.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    /* @apply font-poppins text-black; */
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
  }

  @media all {
    .page-break {
      display: none;
    }
  }

  .react-pdf__Page {
    max-width: calc(100% - 2em);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    margin: 1em;
  }

  .react-pdf__Page canvas {
    max-width: 100%;
    height: auto !important;
  }

  .custom-bullet li {
    padding-left: 2rem;
    background-image: url('data:image/svg+xml;utf8,<svg width="27" height="22" viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.625 16.0443L20.375 10.5221M20.375 10.5221L14.625 5M20.375 10.5221L6 10.5221" stroke="black" stroke-width="1.8" stroke-linecap="square"/></svg>');
    background-size: 20px 16px; /* Taille du SVG */
    background-repeat: no-repeat; /* Ne pas répéter l'image de fond */
    background-position: 0 5px; /* Positionner l'image à gauche */
    line-height: 1.5; /* Ajuster la hauteur de ligne pour le centrage vertical */
  }

  .dispositif-card-shadow {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  }

  /*
  ! Since we can't share variables accross 2 namespaces, we must define them twice. Make sure to edit values in :root variables and @page if needed!!!!
  https://stackoverflow.com/a/44738574/13595699
  */
  :root {
    --page-height: 29.7cm;
    --page-width: 21cm;
    --page-margin: 7mm;
  }

  /* ! We define them once again to override the printer's settings */
  @page {
    height: 29.7cm;
    width: 21cm;
    margin: 7mm;
  }

  @media print {
    html,
    body {
      /* @apply font-helvetica; */
      @apply m-0 border-none p-0 text-[11px] text-black shadow-none;
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      @apply mt-4 block;
      page-break-before: auto;
    }
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
